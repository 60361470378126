import { ProcessStepRisks } from '../../../../../api/process';
import Input from '../../../../../components/input/Input';
import Select from '../../../../../components/input/Select';
import './RiskInput.css';

interface Props {
  risk: ProcessStepRisks;
  onUpdate: (updatedRisk: ProcessStepRisks) => void;
}

const RiskInput: React.FC<Props> = ({ risk, onUpdate }) => {
  const ragOptions = [
    { label: 'Green', value: 'GREEN' },
    { label: 'Amber', value: 'AMBER' },
    { label: 'Red', value: 'RED' },
  ];

  return (
    <div className="risk-input">
      <div className="risk-input__left">
        <Input
          id="risk-description"
          labelText="Risk Description"
          type="textarea"
          value={risk.description}
          onChange={(description) => onUpdate({ ...risk, description })}
        />
        <Select
          id="pre-mitigation-rag"
          labelText="Pre-Mitigation RAG"
          options={ragOptions}
          value={risk.preMitigationRag}
          onChange={(rag) => onUpdate({ ...risk, preMitigationRag: rag })}
        />
      </div>
      <div className="risk-input__right">
        <Input
          id="mitigation-description"
          labelText="Mitigation Description (optional)"
          type="textarea"
          value={risk.mitigationDescription || ''}
          onChange={(mitigationDescription) => onUpdate({ ...risk, mitigationDescription })}
        />
        <Select
          id="post-mitigation-rag"
          labelText="Post-Mitigation RAG (optional)"
          options={ragOptions}
          value={risk.postMitigationRag || ''}
          onChange={(rag) => onUpdate({ ...risk, postMitigationRag: rag })}
        />
      </div>
    </div>
  );
};

export default RiskInput;

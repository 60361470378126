import { Fragment, useContext } from 'react';
import { ItemField } from '../../api/process';
import Input from '../input/Input';
import { title } from 'case';
import FileUpload from '../file-upload/FileUpload';
import FileListItem from '../file-list/FileListItem';
import './ItemFields.css';
import { UploadedFile } from '../../api/file';
import { validateValue } from './utils';
import Select from '../input/Select';
import { formatDate, formatDateTimeShort } from '../../utils/date-utils';
import { AppContext } from '../../App';
import { executeFormulaWithData } from '../../modules/case/case-step/utils';

interface Props {
  fields: ItemField[];
  isEditing: boolean;
  values: any;
  setValues: (values: any) => void;
  entityId: string;
  entityType?: UploadedFile['entityType'];
  compact?: boolean;
}

const ItemFields: React.FC<Props> = ({
  fields,
  values,
  setValues,
  isEditing,
  entityId,
  entityType = 'CASE',
  compact = false,
}) => {
  const ctx = useContext(AppContext);

  const formulaItemFields = fields.filter((field) => field.type === 'formula');

  const handleValueUpdate = (itemField: ItemField, value: string) => {
    let updatedData = { ...values, [itemField.name]: value };

    if (formulaItemFields.length) {
      formulaItemFields.forEach((formulaItemField) => {
        const updatedValue = executeFormulaWithData(formulaItemField.formula!, updatedData) || '';
        updatedData = { ...updatedData, [formulaItemField.name]: updatedValue };
      });
    }

    setValues(updatedData);
  };

  return (
    <Fragment>
      {fields.map((field) => {
        const label = `${field.label || title(field.name)}${field.requiredOnCreate ? '*' : ''}`;
        let value = values[field.name] || '';
        const originalValue = value;
        if (!isEditing && value === 'true') {
          value = 'Yes';
        }

        if (!isEditing && value === 'false') {
          value = 'No';
        }

        // Check if value is a UUID and truncate if it is
        if (
          !isEditing &&
          typeof value === 'string' &&
          /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value)
        ) {
          value = value.slice(0, 6) + '...';
        }

        // Check if the value is in a date format, regardless of field type
        if (!isEditing && value && typeof value === 'string') {
          const date = new Date(value);
          if (!isNaN(date.getTime()) && value.match(/^\d{4}-\d{2}-\d{2}(T|\s)/)) {
            const time = new Date(value).toTimeString().slice(0, 5);
            if (time === '00:00') {
              value = formatDate(value, ctx.user.company.locale, ctx.user.company.timezone);
            } else {
              value = formatDateTimeShort(
                value,
                ctx.user.company.locale,
                ctx.user.company.timezone,
              );
            }
          }
        }

        return (
          <div key={field.name} className={compact ? 'item-field__compact' : ''}>
            {!isEditing && field.type !== 'file' && (
              <div className="item-field__view-only">
                <p className="item-field__view-only-label">{label}</p>
                <p className="item-field__view-only-value">{value}</p>
              </div>
            )}
            {!isEditing && field.type === 'file' && (
              <div className="item-field__view-only">
                <p className="item-field__view-only-label">{label}</p>
                {!!originalValue && <FileListItem fileId={originalValue} />}
                {!originalValue && <small>No file added</small>}
              </div>
            )}
            {isEditing && (
              <>
                {field.type !== 'file' && field.type !== 'select' && (
                  <Input
                    id={field.name}
                    type={field.type}
                    labelText={label}
                    value={value as string}
                    onChange={(value: string) => handleValueUpdate(field, value)}
                    readOnly={!isEditing}
                    invalid={!validateValue(field, value)}
                    minLength={field.minLength}
                    maxLength={field.maxLength}
                    disabled={field.type === 'formula'}
                    helpText={field.type === 'formula' ? 'Calculated' : ''}
                  />
                )}
                {field.type === 'select' && (
                  <Select
                    id={field.name}
                    key={field.name}
                    labelText={label}
                    value={value}
                    onChange={(value: string) => handleValueUpdate(field, value)}
                    options={field.options || []}
                    invalid={!validateValue(field, value)}
                  />
                )}
                {field.type === 'file' && (
                  <div className="item-field__file">
                    <p style={{  }} className="input__label">
                      {label}
                    </p>
                    {!originalValue && !!isEditing && (
                      <FileUpload
                        entityId={entityId}
                        entityType={entityType}
                        onUploadFn={(uploadedFile) => {
                          handleValueUpdate(field, uploadedFile.id);
                        }}
                      />
                    )}
                    {!originalValue && !isEditing && <small>No file added</small>}
                    {!!originalValue && (
                      <div>
                        <FileListItem fileId={originalValue} />
                        {isEditing && (
                          <button
                            style={{ textAlign: 'center', fontSize: '12px', float: 'right' }}
                            className="link-button"
                            onClick={() => handleValueUpdate(field, '')}
                          >
                            Remove
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        );
      })}
    </Fragment>
  );
};

export default ItemFields;

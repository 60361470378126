import ReactFlow, {
  ConnectionLineType,
  Edge,
  Node,
  OnNodesChange,
  Position,
  useUpdateNodeInternals,
} from 'reactflow';
import * as api from '../../api';
import DecisionNode from './DecisionNode';
import StepNode from './StepNode';
import { createContext, useEffect, useState } from 'react';
import { generateNodesFromSteps, getIdsFromEdgeId } from './utils';
import { MapPosition } from '../../api/process';

interface Props {
  processSteps: api.processes.Step[];
  activeStepId?: string;
  onStepSelect?: (stepId: string) => void;
  isEditable?: boolean;
  onStepUpdate?: (id: string, position: MapPosition) => void;
  onPreviousStepAdd?: (source: ConnectableStep, target: ConnectableStep) => void;
  onPreviousStepRemove?: (stepToUpdateId: string, previousStepIdToRemove: string) => void;
  onEdgeSelect?: (edge?: { stepId: string; previousStepId: string }) => void;
}

const nodeTypes = {
  decision: DecisionNode,
  step: StepNode,
};

export interface ConnectableStep {
  id: string;
  position: Position;
}

interface IProcessMapContext {
  source?: ConnectableStep;
  target?: ConnectableStep;
  setSource: (step: ConnectableStep) => void;
  setTarget: (step: ConnectableStep) => void;
  isEditable: boolean;
}

export const ProcessMapContext = createContext<IProcessMapContext>({
  setSource: () => {},
  setTarget: () => {},
  isEditable: false,
});

const ProcessMapInner: React.FC<Props> = ({
  processSteps,
  activeStepId,
  onStepSelect,
  isEditable,
  onStepUpdate,
  onPreviousStepAdd,
  onPreviousStepRemove,
  onEdgeSelect,
}) => {
  const updateNodeInternals = useUpdateNodeInternals();
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);

  const [source, setSource] = useState<ConnectableStep>();
  const [target, setTarget] = useState<ConnectableStep>();

  const context: IProcessMapContext = {
    source,
    target,
    setSource,
    setTarget,
    isEditable: isEditable || false,
  };

  useEffect(() => {
    if (source && target && onPreviousStepAdd) {
      onPreviousStepAdd(source, target);
      setSource(undefined);
      setTarget(undefined);
    }
  }, [source, target]);

  useEffect(() => {
    const { nodes, edges } = generateNodesFromSteps(processSteps, activeStepId);
    setNodes(nodes);
    setEdges(edges);
  }, [processSteps, activeStepId]);

  useEffect(() => {
    const stepIds = processSteps.map((step) => step.id);
    updateNodeInternals(stepIds);
  }, [processSteps]);

  const handleStepSelect = (event: any) => {
    if (!onStepSelect) return;
    console.log('event', event);
    // if (event.length !== 1) return;
    
    const selectEvent = event.find((eventItem: any) => eventItem.selected);

    console.log('selectEvent', selectEvent);
    const stepId = selectEvent?.id;
    
    if (stepId) {
      console.log('onStepSelect', stepId);
      onStepSelect(stepId);
    }
  };

  let onNodesChangeFn: OnNodesChange | undefined
  if (onStepSelect) {
    onNodesChangeFn = handleStepSelect
  }

  if (!!isEditable) {
    onNodesChangeFn = (e) => {
      // onNodesChange(e);
      const event = e[0];
      // @ts-ignore
      if (event.position) {
        // @ts-ignore
        const { id, position } = event;

        const updatedNodes = nodes.map((node) => {
          if (node.id === id) {
            return {
              ...node,
              position,
            };
          }
          return node;
        });
        if (onStepUpdate) {
          onStepUpdate(id, position);
        }
        setNodes(updatedNodes);
      }
    }
  }

  return (
    <ProcessMapContext.Provider value={context}>
      <ReactFlow
        fitView
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChangeFn}
        onEdgesChange={
          onEdgeSelect
            ? (e) => {
                const event = e[0];
                console.log(event);
                if (event.type === 'remove') {
                  const { id } = event;
                  const updatedEdges = edges.filter((edge) => {
                    return edge.id !== id;
                  });
                  setEdges(updatedEdges);
                  // id: `e${currentStep.stepNumber - 1}-${currentStep.stepNumber}-${currentStep.id}-${previousStep.id}`,
                  // TODO: update process step connection here
                  if (onPreviousStepRemove) {
                    const { currentStepId, previousStepId } = getIdsFromEdgeId(id);
                    onPreviousStepRemove(currentStepId, previousStepId);
                  }
                }

                if (onEdgeSelect && event.type === 'select' && event.selected) {
                  const { currentStepId, previousStepId } = getIdsFromEdgeId(event.id);
                  onEdgeSelect({ stepId: currentStepId, previousStepId });
                }

                if (onEdgeSelect && event.type === 'select' && !event.selected) {
                  onEdgeSelect();
                }
              }
            : undefined
        }
        nodeTypes={nodeTypes}
        nodesDraggable={isEditable}
        connectionLineType={ConnectionLineType.Step}
      />
    </ProcessMapContext.Provider>
  );
};

export default ProcessMapInner;

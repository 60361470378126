import { Fragment } from 'react';
import { Process, Step } from '../../../api/process';
import { Automation } from '../../../api/process';
import Input from '../../../components/input/Input';
import AvailableFields from './AvailableFields';
import Turndown from 'turndown';
import { marked } from 'marked';
import {
  MDXEditor,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  toolbarPlugin,
  BoldItalicUnderlineToggles,
  BlockTypeSelect,
  linkPlugin,
  CreateLink,
} from '@mdxeditor/editor';
import './ConfigureEmailAutomation.css';

// Initialize Turndown for HTML to Markdown conversion
const turndownService = new Turndown();

const htmlToMarkdown = (html: string): string => {
  if (!html) return '';
  
  return turndownService.turndown(html);
};

const markdownToHTML = (markdown: string): string => {
  if (!markdown) return '';

  const html = marked.parse(markdown).toString();
  
  return html;
};

interface Props {
  process: Process;
  stepId: string;
  onChange: (stepAutomation: Automation) => void;
  stepAutomation?: Automation;
  allSteps: Step[];
  onSave: () => void;
}

const ConfigureEmailAutomation: React.FC<Props> = ({
  process,
  stepId,
  onChange,
  stepAutomation,
  allSteps,
  onSave,
}) => {
  const allowSave = stepAutomation?.email?.to && stepAutomation?.email?.subject && stepAutomation?.email?.body && stepAutomation?.email?.name;

  const handleEmailBodyChange = async (markdownValue: string) => {
    const htmlValue = markdownToHTML(markdownValue);

    // console.log({markdownValue, htmlValue})

    onChange({
      type: 'EMAIL',
      ...stepAutomation,
      email: { 
        ...stepAutomation?.email, 
        body: htmlValue,
      } as Automation['email'],
    });
  };

  return (
    <Fragment>
      <h2>Configure Email Automation</h2>
      <div className="configure-email-automation__container">
        <div className="configure-email-automation__inputs">
          <div>
            <Input
              id="email-name"
              labelText="Email Automation Name"
              helpText="Internal name for the email automation, this will not be visible in the email sent."
              value={stepAutomation?.email?.name || ''}
              onChange={(value) =>
                onChange({
                  type: 'EMAIL',
                  ...stepAutomation,
                  email: { ...stepAutomation?.email, name: value } as Automation['email'],
                })
              }
            />
            <Input
              id="email-to"
              labelText="To"
              value={stepAutomation?.email?.to || ''}
              onChange={(value) =>
                onChange({
                  type: 'EMAIL',
                  ...stepAutomation,
                  email: { ...stepAutomation?.email, to: value } as Automation['email'],
                })
              }
            />
            <Input
              id="email-cc"
              labelText="CC (optional)"
              value={stepAutomation?.email?.cc || ''}
              onChange={(value) =>
                onChange({
                  type: 'EMAIL',
                  ...stepAutomation,
                  email: { ...stepAutomation?.email, cc: value } as Automation['email'],
                })
              }
            />
            <Input
              id="email-subject"
              labelText="Subject"
              value={stepAutomation?.email?.subject || ''}
              onChange={(value) =>
                onChange({
                  type: 'EMAIL',
                  ...stepAutomation,
                  email: { ...stepAutomation?.email, subject: value } as Automation['email'],
                })
              }
            />
          </div>
          <p>Email Body</p>
          <MDXEditor
            className="email-body-editor"
            markdown={htmlToMarkdown(stepAutomation?.email?.body || '')}
            contentEditableClassName="email-body-editor-content"
            onChange={handleEmailBodyChange}
            plugins={[
              headingsPlugin(),
              listsPlugin(),
              quotePlugin(),
              thematicBreakPlugin(),
              markdownShortcutPlugin(),
              toolbarPlugin({
                toolbarContents: () => (
                  <>
                    <BlockTypeSelect />
                    <BoldItalicUnderlineToggles />
                    <CreateLink />
                  </>
                ),
              }),
              linkPlugin(),
            ]}
          />
          <Input
            id="automation-step-data-required"
            labelText="Does step data need to be completed before sending the email?"
            type="checkbox"
            value={stepAutomation?.email?.requiresCurrentStepData ? 'true' : 'false'}
            onChange={(requiresCurrentStepData) =>
              onChange({
                type: 'EMAIL',
                ...stepAutomation,
                email: { ...stepAutomation?.email, requiresCurrentStepData: requiresCurrentStepData === 'true' } as Automation['email'],
              })
            }
          />
          <Input
            id="automation-one-email-per-item"
            labelText={`Do you want to send one email per ${process.itemName}?`}
            type="checkbox"
            value={stepAutomation?.email?.emailPerItem ? 'true' : 'false'}
            onChange={(emailPerItem) =>
              onChange({
                type: 'EMAIL',
                ...stepAutomation,
                email: { ...stepAutomation?.email, emailPerItem: emailPerItem === 'true' } as Automation['email'],
              })
            }
          />
          <small
            style={{ marginTop: '-16px', marginBottom: '16px', display: 'block' }}
          >{`It's recommended to check this only if you're sending the email to a different email address for each ${process.itemName}. Leaving this unchecked will render a list of ${process.itemName}s in the email wherever you reference a ${process.itemName}s variable.`}</small>
        </div>
        <div className='configure-automation__letter-info'>
          <AvailableFields
            process={process}
            currentStepId={stepId}
            allSteps={allSteps}
            requiresCurrentStepData={stepAutomation?.email?.requiresCurrentStepData}
            context="email"
          />
        </div>
      </div>
      <button style={{ marginTop: '32px' }} onClick={onSave} className='button' disabled={!allowSave}>Save</button>
    </Fragment>
  );
};

export default ConfigureEmailAutomation;

import { Process, ProcessStepRisks, Step } from '.'
import { baseMethods,  } from '..'

export const list = async () => {
  return baseMethods.get<Process[]>({
    path: '/admin/processes',
  })
}
export type NewProcessFields = Partial<Process> & Required<Pick<Process, 'name' | 'itemName' | 'itemDisplayField' | 'itemFields' | 'category' | 'ownerGroups' | 'taxonomyLabel'>>

export const create = async (process: NewProcessFields) => {
  return baseMethods.post<Process>({
    path: '/admin/process',
    body: process,
  })
}

export const update = async (id: string, process: Partial<Process>) => {
  return baseMethods.patch<Process>({
    path: `/admin/process/${id}`,
    body: process,
  })
}

export const get = async (id: string) => {
  return baseMethods.get<Process>({
    path: `/admin/process/${id}`,
  })
}

export const listSteps = async (processId: string) => {
  return baseMethods.get<Step[]>({
    path: `/admin/process/${processId}/steps`,
  })
}

export const updateStep = async (stepId: string, updates: Partial<Step>) => {
  return baseMethods.patch<Step>({
    path: `/admin/process-step/${stepId}`,
    body: updates,
  })
}

export const bulkUpsertSteps = async (processId: string, steps: Partial<Step>[]) => {
  return baseMethods.post<Step[]>({
    path: `/admin/process/${processId}/steps`,
    body: steps,
  })
}

export const bulkUpsertStepRisks = async (processId: string, risks: Partial<ProcessStepRisks>[]) => {
  return baseMethods.post<ProcessStepRisks[]>({
    path: `/admin/process/${processId}/step-risks`,
    body: risks,
  })
}
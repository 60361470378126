import { useContext, useEffect, useState } from 'react';
import { SupervisorOustandingTask, CalendarViewCase } from '../../api/case';
import * as api from '../../api';
import { AppContext } from '../../App';
import { Link, useNavigate } from 'react-router-dom';
import Table, { HeaderComponent } from '../../components/table/Table';
import DueDate from '../../components/due-date/DueDate';
import ProgressBar from '../../components/progress-bar/ProgressBar';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import AnalyticsFiltersComponent, { emptyFilters } from '../../modules/analytics/AnalyticsFilters';
import { AnalyticsFilters } from '../../api/analytics';
import CalendarView from '../../modules/supervisor/CalendarView';
import './SupervisorPage.css';

interface Props {}

const SupervisorPage: React.FC<Props> = () => {
  const ctx = useContext(AppContext);
  const navigate = useNavigate();

  if (!ctx.user.roles.includes('supervisor')) {
    navigate('/');
  }

  const [outstandingTasks, setOutstandingTasks] = useState<SupervisorOustandingTask[]>([]);
  const [calendarViewData, setCalendarViewData] = useState<CalendarViewCase[]>([]);
  const [filters, setFilters] = useState<AnalyticsFilters>({
    ...emptyFilters(true),
  });
  const [availableFilters, setAvailableFilters] = useState<AnalyticsFilters>({});

  const getOutstandingTasks = async () => {
    const res = await api.cases.getSupervisorOutstandingTasks(filters);
    if (res.data) {
      setOutstandingTasks(res.data);
    }
  };

  const getCalendarViewData = async () => {
    const res = await api.cases.getSupervisorCalendar(filters);
    if (res.data) {
      setCalendarViewData(res.data);
    }
  };

  const getAvailableFilters = async () => {
    const res = await api.cases.getSupervisorFilters();
    if (res.data) {
      setAvailableFilters(res.data);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getOutstandingTasks();
      getCalendarViewData();
      getAvailableFilters();
    });
  }, [filters]);

  const dueDateHeader: HeaderComponent<SupervisorOustandingTask> = (record) => {
    return {
      header: 'Active Step Due date',
      element: record?.dueDate ? <DueDate date={record.dueDate} /> : 'n/a',
    };
  };

  const caseDueDateHeader: HeaderComponent<SupervisorOustandingTask> = (record) => {
    return {
      header: 'Case Due date',
      element: record?.caseDueDate ? <DueDate date={record.caseDueDate} /> : 'n/a',
    };
  };

  const caseIdHeader: HeaderComponent<SupervisorOustandingTask> = (record) => {
    return {
      element: (
        <Link to={`/case/${record?.caseId}`}>
          {record?.nickname ? `${record?.nickname} (${record?.code})` : record?.code}
        </Link>
      ),
      header: 'Case',
    };
  };

  const currentUsersHeader: HeaderComponent<SupervisorOustandingTask> = (record) => {
    const stepOwnerGroups = record?.ownerGroups.split(',') || [];
    const currentUsers = record?.userRoles.filter((userRole) => {
      const userCaseRoles = userRole.roles.split(',');
      const userHasStepRoles = stepOwnerGroups.some((stepOwnerGroup) =>
        userCaseRoles.includes(stepOwnerGroup),
      );
      return userHasStepRoles;
    });

    const userNames = currentUsers?.map((currentUser) => currentUser.user.name) || [];

    return {
      element: userNames.join(', '),
      header: 'Step owner',
    };
  };

  const progressHeader: HeaderComponent<SupervisorOustandingTask> = (record) => {
    return {
      element: <ProgressBar value={record?.completionPercentage || 0} />,
      header: 'Completion',
    };
  };

  const headers: (HeaderComponent<SupervisorOustandingTask> | keyof SupervisorOustandingTask)[] = [
    caseIdHeader,
    'processName',
    (record) => ({ header: 'Step Name', element: record?.processStepName || '' }),
    caseDueDateHeader,
    dueDateHeader,
    currentUsersHeader,
    progressHeader,
  ];

  return (
    <main className="content">
      <div className="supervisor-page-container">
        <div className="box">
          <h1>Supervisor View</h1>
        </div>
        <div className="supervisor-content-container">
          <div className="filters-container box">
            <h2>Filters</h2>
            <AnalyticsFiltersComponent
              filters={filters}
              setFilters={setFilters}
              availableFilters={availableFilters}
              hideExcludeDates={true}
            />
          </div>
          <div className="tabs-container box">
            <Tabs>
              <TabList>
                <Tab>Calendar View</Tab>
                <Tab>Table View</Tab>
              </TabList>
              <TabPanel>
                <h2>Calendar View</h2>
                <CalendarView
                  outstandingTasks={calendarViewData}
                  startDate={filters.startDate!}
                  endDate={filters.endDate!}
                />
              </TabPanel>
              <TabPanel>
                <h2>Outstanding Tasks</h2>
                <Table data={outstandingTasks} headers={headers} search={true} />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SupervisorPage;

import { endOfMonth, startOfMonth } from 'date-fns';
import { format } from 'date-fns-tz';
import { AnalyticsFilters } from '../../api/analytics';
import { Case } from '../../api/case';
import Input from '../../components/input/Input';
import FilterCheckboxList from './FilterCheckboxList';
import { TrashIcon } from '@heroicons/react/24/outline';
import './AnalyticsFilters.css';

interface Props {
  filters?: AnalyticsFilters;
  availableFilters?: AnalyticsFilters;
  setFilters: (filters: AnalyticsFilters) => void;
  hideExcludeDates?: boolean;
}

// Get the current date in UTC
const currentDate = new Date();

// Convert UTC dates to the target timezone (in this case, keeping it UTC)
const threeMonthsAgo = new Date(currentDate);
threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
const startOfThreeMonthsAgo = startOfMonth(threeMonthsAgo);
const lastMonth = new Date(currentDate);
lastMonth.setMonth(lastMonth.getMonth() - 1);
const endOfLastMonth = endOfMonth(lastMonth);

const startOfThreeMonthsAgoUTCString = format(startOfThreeMonthsAgo, 'yyyy-MM-dd', { timeZone: 'UTC' });
const endOfLastMonthUTCString = format(endOfLastMonth, 'yyyy-MM-dd', { timeZone: 'UTC' });

const startOfThisMonthUTCString = format(startOfMonth(currentDate), 'yyyy-MM-dd', { timeZone: 'UTC' });
const endOfThisMonthUTCString = format(endOfMonth(currentDate), 'yyyy-MM-dd', { timeZone: 'UTC' });

export const emptyFilters = (futureDates?: boolean) => {
  return {
    // Default to start of the current month in UTC
    startDate: futureDates ? startOfThisMonthUTCString : startOfThreeMonthsAgoUTCString,
    // Default to end of the current month in UTC
    endDate: futureDates ? endOfThisMonthUTCString : endOfLastMonthUTCString,
    processIds: [],
    caseStatuses: [],
    processCategories: [],
  };
};

const AnalyticsFiltersComponent: React.FC<Props> = ({ filters, setFilters, availableFilters, hideExcludeDates }) => {
  return (
    <div className="analytics-filters">
      <div className="analytics-filters__header">
        <h1 className="analytics-filters__title">Analytics</h1>
        <button
          className="link-button analytics-filters__clear-button"
          style={{ marginLeft: 'auto' }}
          onClick={() => setFilters(emptyFilters())}
        >
          Clear
          <TrashIcon />
        </button>
      </div>

      <div className="analytics-filters__date-inputs">
        <Input
          id="start-date"
          labelText="Date from"
          value={filters?.startDate || ''}
          onChange={(startDate) => setFilters({ ...filters, startDate })}
          type="date"
          small={true}
        />
        <Input
          id="end-date"
          labelText="Date to"
          value={filters?.endDate || ''}
          onChange={(endDate) => setFilters({ ...filters, endDate })}
          type="date"
          small={true}
        />
      </div>

      {!hideExcludeDates && (
        <Input
          id="exclude-dates"
          labelText="Cases without due dates"
          type="checkbox"
          value={(!filters?.startDate)?.toString() || ''}
        onChange={(val) => {
          console.log(val);
          if (val === 'true') {
            setFilters({
              ...filters,
              startDate: undefined,
              endDate: undefined,
            });
          } else {
            setFilters({
              ...filters,
              startDate: emptyFilters(false).startDate,
              endDate: emptyFilters(false).endDate,
            });
          }
          }}
        />
      )}
      <FilterCheckboxList
        availableFilters={availableFilters?.processIds}
        selectedFilters={filters?.processIds}
        setFilters={(processIds) => setFilters({ ...filters, processIds })}
        labelText="Processes"
      />
      <FilterCheckboxList
        availableFilters={availableFilters?.processCategories}
        selectedFilters={filters?.processCategories}
        setFilters={(processCategories) => setFilters({ ...filters, processCategories })}
        labelText="Process Categories"
      />

      <FilterCheckboxList
        availableFilters={availableFilters?.caseStatuses}
        selectedFilters={filters?.caseStatuses}
        setFilters={(caseStatuses) =>
          setFilters({ ...filters, caseStatuses: caseStatuses as Case['status'][] })
        }
        labelText="Case Statuses"
      />
    </div>
  );
};

export default AnalyticsFiltersComponent;

import { baseMethods,  } from '..'
import { DataMapping, Integration } from '../integration/admin';
import * as admin from './admin'

export interface Automation {
  type: 'LETTER' | 'CSV_DOWNLOAD' | 'EMAIL',
  letter?: {
    templateFileId: string;
    requiresCurrentStepData: boolean;
    name: string
    letterPerItem?: boolean;
  }
  csvDownload?: {
    fileName: string;
    dataMapping: DataMapping[]
  }
  email?: {
    name: string;
    requiresCurrentStepData: boolean;
    to: string;
    cc: string;
    subject: string;
    body: string;
    includeLinkToCase: boolean;
    emailPerItem?: boolean;
  }
}

export type ItemField = {
  label?: string;
  name: string;
  type: 'text' | 'number' | 'date' | 'select' | 'textarea' | 'file' | 'select' | 'formula' | 'currency' | 'checkbox'
  options?: { value: string, label: string}[]
  requiredOnCreate?: boolean
  formula?: string
  maxLength?: number
  minLength?: number
}

export type MapPosition = {
  x: number;
  y: number;
}

export type PreviousStep = {
  id: string;
  stepEdge: 'top' | 'left' | 'right' | 'bottom';
  previousStepEdge: 'top' | 'left' | 'right' | 'bottom';
  /** used when a step is linked to a decision to represent the decision made - usually "Yes" or "No" */
  label?: string
}

export type ProcessStepRisks = {
  id: string;
  processStepId: string;
  description: string;
  preMitigationRag: string;
  postMitigationRag?: string;
  mitigationDescription?: string;
}

export type Step = {
  id: string;
  taxonomyLabel?: string;
  name: string;
  description: string | null;
  guidanceNotes?: string;
  type: string;
  stepNumber: number;
  track: number;
  autoComplete: boolean;
  autoDecisionFormula?: string;
  previousStepIds?: string[];
  dueInDays?: number;
  dueInSentence?: string;
  previousStepLabel?: string;
  nextStepIds: string[] | null;
  processId: string;
  companyId: string;
  current?: boolean;
  supportingData?: ItemField[]
  controlOutcome?: 'PASS' | 'FAIL';
  ownerGroups: string[];
  automation?: Automation;
  mapPosition?: MapPosition;
  previousSteps?: PreviousStep[];
  requireProcessItemsHere?: boolean;
  risks?: ProcessStepRisks[];
}

export type Process = {
  id: string;
  taxonomyLabel?: string;
  name: string;
  category?: string;
  published: boolean;
  dueInSentence?: string;
  description: string | null;
  itemName: string;
  itemDisplayField: string;
  itemFields: ItemField[];
  supportingData: ItemField[];
  ownerGroups: string[];
  companyId: string;
  integrations: Integration[];
  steps: Step[];
  maxItems?: number
}

export const get = (id: string) => {
  return baseMethods.get<Process>({
    path: `/process/${id}`,
  })
}

export const list = async () => {
  return baseMethods.get<Process[]>({
    path: `/process`,
  })
}

export { admin }
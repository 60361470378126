import { CalendarViewCase } from '../../api/case';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, startOfWeek, endOfWeek, parseISO, isWeekend, isFirstDayOfMonth } from 'date-fns';
import ProgressBar from '../../components/progress-bar/ProgressBar';
import { Link } from 'react-router-dom';
import './CalendarView.css';

interface Props {
  outstandingTasks: CalendarViewCase[];
  startDate: string;
  endDate: string;
}

const CalendarView: React.FC<Props> = ({ outstandingTasks, startDate, endDate }) => {
  const monthStart = startOfMonth(parseISO(startDate));
  const monthEnd = endOfMonth(parseISO(endDate));
  
  // Get the start of the week for the first day of the month
  const calendarStart = startOfWeek(monthStart, { weekStartsOn: 1 }); // Start on Monday
  const calendarEnd = endOfWeek(monthEnd, { weekStartsOn: 1 });

  const days = eachDayOfInterval({
    start: calendarStart,
    end: calendarEnd,
  });

  const getTasksForDay = (date: Date) => {
    return outstandingTasks.filter(task => {
      if (!task.caseDueDate) {
        return false;
      }
      const caseDueDate = parseISO(task.caseDueDate);
      return format(caseDueDate, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd');
    });
  };

  const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  return (
    <div className="calendar-view">
      <div className="calendar-header">
        {weekDays.map(day => (
          <div key={day} className="calendar-header-cell">
            {day}
          </div>
        ))}
      </div>
      <div className="calendar-grid">
        {days.map(day => {
          const tasksForDay = getTasksForDay(day);
          const isOutsideMonth = !isSameMonth(day, monthStart);
          const isWeekendDay = isWeekend(day);

          return (
            <div 
              key={format(day, 'yyyy-MM-dd')} 
              className={`calendar-day ${isWeekendDay ? 'weekend' : ''}`}
            >
              <div className="calendar-day-header">
                <div>
                  {isFirstDayOfMonth(day) && (
                    <span className="calendar-month-label">{format(day, 'MMMM')}</span>
                  )}
                  <span className="calendar-day-number">{format(day, 'd')}</span>
                </div>
              </div>
              <div>
                {tasksForDay.map(task => (
                  <div 
                    key={task.caseId} 
                    className={`calendar-case-item ${String(task.completionPercentage) === '1.00' ? 'calendar-case-item--complete' : ''}`}
                  >
                    <Link to={`/case/${task.caseId}`} className="calendar-case-link">
                    <div className="calendar-case-process-name">
                        {task.processName}
                      </div>
                      <div className="calendar-case-code">
                        {task.nickname ? `${task.nickname} (${task.code})` : task.code}
                      </div>
                      
                      <ProgressBar 
                        value={task.completionPercentage} 
                        color={String(task.completionPercentage) === '1.00' ? 'green' : 'default'} 
                      />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CalendarView;